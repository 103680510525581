import React, { useEffect, useState } from 'react';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { adminRights as defaultAdminRight } from 'utils/constants';
import pluralize from 'utils/pluralize';
import {
  ACTIVATION,
  ADMINRIGHTS,
  CREATE_SHOP,
  GROUP,
  USERPROFILE,
  USERS,
  VIEWS
} from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from './components/ModalDelete';
import { ModalForm } from './components/ModalForm';
import { ModalStepper } from './components/ModalStepper';

const simplifiedRights = {
  ...defaultAdminRight,
  general: {
    dashboard: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    customers: {
      create: false,
      read: false,
      update: false,
      delete: false,
      advanced: false
    },
    tarification: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    groups: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    users: {
      create: true,
      read: true,
      update: true,
      delete: false
    },
    connection: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    connector: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    admin: {
      create: false,
      update: false,
      delete: false
    },
    adminRights: {
      update: false
    },
    domain: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    activableMedias: {
      create: false,
      read: false,
      update: false
    }
  }
};

const UserContainer = () => {
  const { themeColors } = useTheme();
  const { domainConfigs, isSimplify, domainConfig } = useApp();
  const {
    adminRights: adminRightsContext,
    setAdminRights,
    user,
    fetchUser
  } = useUser();

  const [groupsList, setGroups] = useState([]);
  const [adminPresetList, setAdminPresetList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [roles, setRoles] = useState({});
  const [usersList, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [shopGroups, setShopGroups] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [viewList, setViewList] = useState([]);

  const closeModal = () => {
    setModal(null);
    setSelectedUser({});
  };

  const selectUser = id => {
    const currentUser = usersList.find(el => el._id === id);
    currentUser.groups = groupsList.filter(
      group => !!group.userIds?.find(el => el === currentUser._id)
    );
    setSelectedUser(currentUser);
    return currentUser;
  };

  const getUsers = async () => {
    try {
      const result = await USERS.getAll();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getGroups = async () => {
    try {
      const result = await GROUP.getGroups();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getRoles = async () => {
    try {
      const result = await USERS.getRoles();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getAdminRights = async () => {
    try {
      const result = await USERS.getAdminRights();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getAdminRightsPresets = async () => {
    try {
      const result = await ADMINRIGHTS.getPresetList();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getProfiles = async () => {
    try {
      const result = await USERPROFILE.get();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getViews = async () => {
    try {
      const result = await VIEWS.get();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatGroups = groups => {
    const nbGroups = groups.length;
    if (nbGroups === 0) {
      return <Text>Aucun</Text>;
    }
    if (nbGroups === 1)
      return <Text color="global"> {groups[0] && groups[0].name} </Text>;

    const others = nbGroups - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      'groupe',
      others
    )}`;

    const tooltipContent = () => {
      return (
        <Container>{groups.map(group => getToolTipText(group.name))}</Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="global"> {groups[0].name}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="global">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const formatDomains = (domains = []) => {
    const nbDomain = domains.length;
    if (nbDomain === 0) {
      return <Text>Aucun</Text>;
    }
    const mappedDomain = domains.map(
      el => domainConfigs.find(config => config.domain === el)?.name
    );

    if (nbDomain === 1) {
      return <Text color="global"> {mappedDomain[0]} </Text>;
    }

    const others = nbDomain - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      'domaine',
      others
    )}`;

    const tooltipContent = () => {
      return (
        <Container>{mappedDomain.map(name => getToolTipText(name))}</Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="global">
              {' '}
              {mappedDomain[0]}
              &nbsp;
            </Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="global">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  useEffect(() => {
    const getDatas = async () => {
      const [
        users,
        groups,
        rolesList,
        adminRightsList,
        adminRightsPresets,
        profiles,
        views
      ] = await Promise.all([
        getUsers(),
        getGroups(),
        getRoles(),
        getAdminRights(),
        getAdminRightsPresets(),
        getProfiles(),
        getViews()
      ]);

      setProfileList(profiles);
      setViewList(views);

      if (isSimplify) {
        const shops = await ACTIVATION.getActivationShopGoups();
        setShopGroups(shops);
      }

      const profileKeysToShow = profiles.reduce((acc, curr) => {
        if (curr.showInSimplified) {
          acc.push(curr.key);
        }
        return acc;
      }, []);
      const mapped = users.reduce((acc, item) => {
        if (
          isSimplify &&
          !item.roles.some(el => el.name === 'MAGASIN') &&
          profileKeysToShow.indexOf(item.profile) === -1
        ) {
          return acc;
        }

        const newItem = {};
        newItem._id = item._id;
        newItem.id = item._id;
        newItem.familyName = item.family_name;
        newItem.givenName = item.given_name;
        newItem.email = item.email;
        const userGroups = groups?.filter(
          group => !!group.userIds?.find(userId => userId === item._id)
        );
        newItem.groups = formatGroups(userGroups);
        newItem.groupLength = userGroups.length;
        newItem.externalId = item.externalId;
        newItem.role = item.roles[0]?.name || 'AUCUN';
        newItem.domains = formatDomains(item.domains, domainConfigs);

        acc.push(newItem);
        return acc;
      }, []);

      setUsers(
        users.map(item => {
          const newItem = item;
          newItem.adminRights = adminRightsList.find(
            el => el.userId === item._id
          );
          return newItem;
        })
      );

      const tmpRoles = !isSimplify
        ? rolesList
        : rolesList.filter(el => el.name === 'MAGASIN');
      setGroups(groups);
      setAdminPresetList(adminRightsPresets);
      setRoles(tmpRoles);
      setRows(mapped);
      setAllRows(mapped);
      const sortedDomains = domainConfigs
        .map(({ domain, name }) => ({
          key: domain,
          label: `${name} (${domain})`
        }))
        .sort((a, b) => {
          const labelA = a.label?.toLowerCase();
          const labelB = b.label?.toLowerCase();
          if (labelA > labelB) {
            return 1;
          }
          if (labelA < labelB) {
            return -1;
          }
          return 0;
        });
      setDomainList(sortedDomains);
      setLoaded(true);
    };

    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleCreateUser = async (
    { groups, adminRights, ...datas },
    shopDatas
  ) => {
    try {
      if (isSimplify) {
        setModal('stepper');
        const newUser = await USERS.createUser(datas);
        if (newUser) {
          await Promise.all(
            groups.map(async group => {
              GROUP.addUser({
                groupId: group._id,
                userId: newUser.externalId
              });
            })
          );
          const newRole = datas.roles.map(el => {
            return roles.find(item => item.id === el)?.name || 'unknown';
          });
          if (newRole.indexOf('ADMIN') !== -1) {
            await USERS.createAdminRights({
              userId: newUser.externalId,
              rights: adminRights
            });
          }
          const isAdmin = newRole.indexOf('ADMIN') !== -1;
          if (!isAdmin && datas.additionalInformation.canAccessBO) {
            // Création de l'admin right pour les utilisateurs BO simplifié
            await USERS.createAdminRights({
              userId: newUser.externalId,
              rights: simplifiedRights
            });
          }
        }
        setCurrentStep(1);

        const activationShops = [];
        for (const shop of shopDatas) {
          const insightShopData = {
            name: shop?.name,
            address: shop?.address,
            origin: shop?.origin,
            audienceType: shop?.audienceType,
            parameters: shop?.parameters
          };

          const activationShopData = {
            key: shop?.key,
            name: shop?.name,
            owner: newUser?.externalId,
            users: [newUser?.externalId],
            acceptedMedias: domainConfig?.acceptedMedias || [],
            domain: shop?.domain,
            create: true,
            siteUri: shop?.siteUrl
          };

          const { activationShop } = await CREATE_SHOP.createShop({
            activationShopData,
            insightShopData,
            owner: newUser
          });

          activationShops.push(activationShop);
        }
        setCurrentStep(2);
        for (const shopData of shopDatas) {
          const shop = shopGroups.find(el => el._id === shopData?.shopGroup);
          if (shop) {
            const shoplist = shop?.shops.concat(
              activationShops
                .filter(el =>
                  shopDatas
                    .filter(element => element?.shopGroup === shop?._id)
                    .map(item => item?.key)
                    .includes(el?.key)
                )
                .map(el => el?._id)
            );

            await ACTIVATION.updateActivationShopGoup(shop?._id, {
              shops: shoplist
            });
          }
        }
        setModal(null);
        setLoaded(false);
      } else {
        const newUser = await USERS.createUser(datas);
        if (newUser) {
          await Promise.all(
            groups.map(async group => {
              GROUP.addUser({
                groupId: group._id,
                userId: newUser.externalId
              });
            })
          );
          const newRole = datas.roles.map(el => {
            return roles.find(item => item.id === el)?.name || 'unknow';
          });
          if (newRole.indexOf('ADMIN') !== -1) {
            await USERS.createAdminRights({
              userId: newUser.externalId,
              rights: adminRights
            });
          }
          const isAdmin = newRole.indexOf('ADMIN') !== -1;
          if (!isAdmin && datas.additionalInformation.canAccessBO) {
            // Création de l'admin right pour les utilisateurs BO simplifié
            await USERS.createAdminRights({
              userId: newUser.externalId,
              rights: simplifiedRights
            });
          }
          setModal(null);
          setLoaded(false);
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleUpdateUser = async ({ groups, adminRights, ...datas }) => {
    await USERS.updateUser(selectedUser.graviteeId, datas);
    for await (const item of groupsList) {
      const userInGroup =
        item.userIds?.filter(userId => userId === selectedUser._id).length !==
        0;
      if (groups.find(el => el.name === item.name)) {
        if (!userInGroup) {
          await GROUP.addUser({
            groupId: item._id,
            userId: selectedUser._id
          });
        }
      } else if (userInGroup) {
        await GROUP.deleteUser({
          groupId: item._id,
          userId: selectedUser._id
        });
      }
    }

    const newRole = datas.roles.map(el => {
      return roles.find(item => item.id === el)?.name || 'unknow';
    });
    const isAdmin = newRole.indexOf('ADMIN') !== -1;
    if (isAdmin) {
      if (!selectedUser.adminRights) {
        await USERS.createAdminRights({
          userId: selectedUser._id,
          rights: adminRights
        });
      } else {
        await USERS.updateAdminRights(selectedUser.adminRights._id, {
          rights: adminRights
        });
      }
      if (selectedUser._id === user._id) {
        setAdminRights(adminRights);
      }
    }
    if (
      !isAdmin &&
      selectedUser.adminRights?._id &&
      !datas.additionalInformation.canAccessBO
    ) {
      await USERS.deleteAdminRights(selectedUser.adminRights._id);
    }
    if (
      !isAdmin &&
      !selectedUser.adminRights?._id &&
      datas.additionalInformation.canAccessBO
    ) {
      // Création de l'admin right pour les utilisateurs BO simplifié
      await USERS.createAdminRights({
        userId: selectedUser._id,
        rights: simplifiedRights
      });
    }

    if (user.graviteeId === selectedUser.graviteeId) {
      await fetchUser();
    }
    setModal(null);
    setLoaded(false);
  };

  const handleDelete = async () => {
    await USERS.deleteUser(selectedUser._id);
    await Promise.all(
      selectedUser.groups.map(async group => {
        GROUP.deleteUser({
          groupId: group._id,
          userId: selectedUser._id
        });
      })
    );
    if (selectedUser.adminRights?._id) {
      await USERS.deleteAdminRights(selectedUser.adminRights._id);
    }
    setLoaded(false);
    closeModal();
  };

  const addUser = () => {
    if (
      adminRightsContext?.general?.users?.create ||
      adminRightsContext?.general?.admin?.create ||
      isSimplify
    ) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const updateUser = id => {
    const currentUser = selectUser(id);
    if (adminRightsContext?.general?.admin?.update) {
      return setModal('modalUpdate');
    }

    if (
      (adminRightsContext?.general?.users?.update || isSimplify) &&
      currentUser.roles[0]?.name !== 'ADMIN'
    ) {
      return setModal('modalUpdate');
    }

    return setModal('unauthorized');
  };

  const deleteUser = id => {
    const currentUser = selectUser(id);
    if (adminRightsContext?.general?.admin?.delete) {
      return setModal('modalDelete');
    }

    if (
      (adminRightsContext?.general?.users?.delete || isSimplify) &&
      currentUser.roles[0]?.name !== 'ADMIN'
    ) {
      return setModal('modalDelete');
    }

    return setModal('unauthorized');
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche un utilisateur"
            placeholder="Id, Email, Nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={addUser}
            text="Ajouter un utilisateur"
            disabled={!loaded}
          />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onValidate={handleCreateUser}
              config={{
                rolesList: roles,
                groupsList,
                adminPresetList,
                domainList,
                shopGroups,
                profileList,
                viewList
              }}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'ID Compte Utilisateur',
                  id: 'externalId',
                  sortKey: 'externalId'
                },
                {
                  label: 'Adresse email',
                  id: 'email',
                  sortKey: 'email'
                },
                {
                  label: 'Prénom',
                  id: 'givenName',
                  sortKey: 'givenName'
                },
                {
                  label: 'Nom',
                  id: 'familyName',
                  sortKey: 'familyName'
                },
                ...(!isSimplify
                  ? [
                      {
                        label: 'Organisation',
                        id: 'groups',
                        sortKey: 'groupLength'
                      }
                    ]
                  : []),
                {
                  label: 'Rôle',
                  id: 'role',
                  sortKey: 'role'
                },
                ...(!isSimplify ? [{ label: 'Domaines', id: 'domains' }] : [])
              ]}
              onUpdate={updateUser}
              onDelete={!isSimplify ? deleteUser : undefined}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onValidate={handleUpdateUser}
              rolesList={roles}
              config={{
                rolesList: roles,
                selectedUser,
                groupsList,
                adminPresetList,
                domainList,
                profileList,
                viewList
              }}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={handleDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
          {openModal === 'stepper' && <ModalStepper activeStep={currentStep} />}
        </Item>
      </Row>
    </Container>
  );
};

export default UserContainer;
