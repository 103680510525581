import React, { useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Checkbox, Select, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  nameError: false,
  keyError: false,
  viewKeyError: false
};
export const ModalForm = props => {
  const { config, onValidate, onClose, actionName, views } = props;
  const { themeColors } = useTheme();
  const initialState = {
    name: config?.name || '',
    key: config?.key || '',
    description: config?.description,
    viewKey: config?.viewKey,
    features: config?.features || {},
    showInSimplified: config?.showInSimplified || true
  };
  const [
    { name, key, description, features, viewKey, showInSimplified },
    setState
  ] = useState(initialState);

  const [{ nameError, keyError, viewKeyError }, setError] = useState(
    ErrorState
  );

  const handleChange = field => value => {
    let newValue = value;
    if (typeof value === 'string') {
      newValue = value.trim();
    }
    setState(prevState => {
      return { ...prevState, [field]: newValue };
    });
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const doAction = () => {
    let error = false;

    if (!name || name.trim().length === 0) {
      toggleError('name', true);
      error = true;
    }

    if (!key || key?.trim().length === 0) {
      toggleError('key', true);
      error = true;
    }
    if (!viewKey || viewKey?.trim().length === 0) {
      toggleError('viewKey', true);
      error = true;
    }

    const datas = {
      name,
      key,
      description,
      showInSimplified,
      features,
      viewKey
    };
    if (!error) {
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.global}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} le profil
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const disableFields = !!config;

  const featuresMapping = [
    { key: 'canEditPAL', label: 'Edition des PALs' },
    { key: 'launchCampaign', label: 'Lancer une campagne' },
    { key: 'canAccessBO', label: 'Accéder au BO' },
    { key: 'isSimplified', label: 'Accéder au BO simplifié' },
    { key: 'enabledPmlFront', label: 'Setup PML en Front' },
    { key: 'accessContent', label: 'Accèder au Creative Builder' },
    {
      key: 'sendCreationEmailOnCampaignValidation',
      label: "Création d'utilisateur à la première campagne"
    },
    {
      key: 'simpleReport',
      label: 'Simplification du bilan (Vue PML Uniquement)'
    }
  ];

  const pmlSectionMapping = [
    { key: 'inProgressSection', label: 'Section Campagnes lancées' },
    { key: 'scheduledSection', label: 'Section Campagnes programmées' },
    { key: 'palSection', label: 'Section PAL' },
    { key: 'draftsSection', label: 'Section Campagnes à finaliser' },
    { key: 'spendingsSection', label: 'Section Dépenses' },
    {
      key: 'newCampaignsSection',
      label: 'Section Création de nouvelle campagne'
    }
  ];

  const getCheckbox = item => {
    return (
      <Item xs={6} justify="flex-start">
        <Row spacing={0} justify="flex-start">
          <Item xs={11} justify="flex-start">
            <Checkbox
              onChange={event => {
                handleChange('features')({
                  ...features,
                  [item.key]: event.target.checked
                });
              }}
              checked={features[item.key]}
            />
            <Text>{item.label}</Text>
          </Item>
        </Row>
      </Item>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text color={nameError ? 'error' : 'inherit'}>
                          Nom du profil *
                        </Text>
                        <TextField
                          label=""
                          error={nameError}
                          onChange={handleChange('name')}
                          value={name}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text color={keyError ? 'error' : 'inherit'}>
                          Key du profil *
                        </Text>
                        <TextField
                          label=""
                          error={keyError}
                          onChange={handleChange('key')}
                          value={key}
                          disabled={disableFields}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text color={viewKeyError ? 'error' : 'inherit'}>
                          Vue *
                        </Text>
                        <Select
                          disabled={disableFields}
                          label=""
                          error={viewKeyError}
                          options={views}
                          onChange={handleChange('viewKey')}
                          value={viewKey}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text>
                          Afficher les utilisateurs du profil dans le BO
                          simplifié
                        </Text>
                        <Switch
                          onChange={handleChange('showInSimplified')}
                          checked={showInSimplified}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={12} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text>Description</Text>
                        <TextField
                          label=""
                          onChange={handleChange('description')}
                          value={description}
                          multiline
                          rows={3}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
            <FormSection title="Droits accordés au profil" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  {featuresMapping.map(item => getCheckbox(item))}
                </Row>
              </Container>
            </FormSection>
            {viewKey === 'pml' && (
              <FormSection
                title="Sections affichées dans le dashboard PML"
                xs={3}
              >
                <Container>
                  <Row spacing={0} justify="flex-start">
                    {pmlSectionMapping.map(item => getCheckbox(item))}
                  </Row>
                </Container>
              </FormSection>
            )}
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalForm;
