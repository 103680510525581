/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Chip, Collapse } from '@mui/material';
import IntervalTimer from 'react-interval-timer';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';
import numeral from 'utils/numeral';
import { ACTIVATION, CONNEXION, IMPORTMODEL } from 'utils/rest';

import { Divider, Text, Tooltip } from 'components/DataDisplay';
import { FormSection } from 'components/FormSection';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';

import {
  AdItem,
  ExpendedItem,
  InvoiceItem,
  LineItem,
  MediaOrderItem,
  TableConnectorComponent
} from './components';

const mockedObjectives = {
  platforms: [
    {
      platform: 'facebook',
      platformLabel: 'Facebook/Instagram',
      objectives: [
        {
          objectiveLabel: 'Ventes',
          objectiveKey: 'vl',
          subObjectives: [
            {
              subObjectiveLabel:
                'Maximiser le nombre de vues de la page de destination',
              subObjectiveKey: 'LANDING_PAGE_VIEWS'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre de clics sur un lien',
              subObjectiveKey: 'LINK_CLICKS'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre d’impressions',
              subObjectiveKey: 'IMPRESSIONS'
            }
          ]
        },
        {
          objectiveLabel: 'Prospects',
          objectiveKey: 'li',
          subObjectives: [
            {
              subObjectiveLabel:
                'Maximiser le nombre de vues de la page de destination',
              subObjectiveKey: 'LANDING_PAGE_VIEWS'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre de clics sur un lien',
              subObjectiveKey: 'LINK_CLICKS'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre d’impressions',
              subObjectiveKey: 'IMPRESSIONS'
            }
          ]
        },
        {
          objectiveLabel: 'Trafic',
          objectiveKey: 'vsw',
          subObjectives: [
            {
              subObjectiveLabel:
                'Maximiser le nombre de vues de la page de destination',
              subObjectiveKey: 'LANDING_PAGE_VIEWS'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre de clics sur un lien',
              subObjectiveKey: 'LINK_CLICKS'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre d’impressions',
              subObjectiveKey: 'IMPRESSIONS'
            },
            {
              subObjectiveLabel:
                'Maximiser le nombre de visites du profil Instagram',
              subObjectiveKey:
                'maximiser-le-nombre-de-visites-du-profil-instagram'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre de prospects',
              subObjectiveKey: 'maximiser-le-nombre-de-prospects'
            }
          ]
        },
        {
          objectiveLabel: 'Notoriété',
          objectiveKey: 'noto',
          subObjectives: [
            {
              subObjectiveLabel: 'Maximiser la couverture des publicités',
              subObjectiveKey: 'REACH'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre d’impressions',
              subObjectiveKey: 'IMPRESSIONS'
            },
            {
              subObjectiveLabel: 'Maximiser les vues ThruPlay',
              subObjectiveKey: 'THRUPLAY'
            },
            {
              subObjectiveLabel:
                'Maximiser les vues de vidéos de 2 secondes consécutives',
              subObjectiveKey: 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS'
            }
          ]
        },
        {
          objectiveLabel: 'Promotion de l’application',
          objectiveKey: 'dam',
          subObjectives: [
            {
              subObjectiveLabel:
                'Maximiser le nombre d’évènements d’application',
              subObjectiveKey: 'OFFSITE_CONVERSIONS'
            },
            {
              subObjectiveLabel:
                'Maximiser le nombre d’installations d’application',
              subObjectiveKey: 'APP_INSTALLS'
            },
            {
              subObjectiveLabel: 'Maximiser le nombre de clics sur un lien',
              subObjectiveKey: 'LINK_CLICKS'
            }
          ]
        },
        {
          objectiveLabel: 'Ventes',
          objectiveKey: 'vo',
          subObjectives: []
        },
        {
          objectiveLabel: 'Ventes',
          objectiveKey: 'vm',
          subObjectives: []
        },
        {
          objectiveLabel: 'Notoriété',
          objectiveKey: 'vem',
          subObjectives: []
        }
      ]
    }
    // TODO Uncomment when Taboola & GADS will be operationnal
    // {
    //   platform: 'taboola',
    //   platformLabel: 'Taboola',
    //   objectives: [
    //     {
    //       objectiveLabel: 'Lead generation',
    //       objectiveKey: 'lead-generation',
    //       subObjectives: []
    //     },
    //     {
    //       objectiveLabel: 'Online purchases',
    //       objectiveKey: 'online-purchases',
    //       subObjectives: []
    //     },
    //     {
    //       objectiveLabel: 'Website engagement',
    //       objectiveKey: 'website-engagement',
    //       subObjectives: []
    //     },
    //     {
    //       objectiveLabel: 'Brand awareness',
    //       objectiveKey: 'brand-awareness',
    //       subObjectives: []
    //     },
    //     {
    //       objectiveLabel: 'App promotion',
    //       objectiveKey: 'app-promotion',
    //       subObjectives: []
    //     }
    //   ]
    // },
    // {
    //   platform: 'googleAds',
    //   platformLabel: 'GADS',
    //   objectives: [
    //     {
    //       objectiveLabel: 'Ventes',
    //       objectiveKey: 'ventes',
    //       subObjectives: []
    //     },
    //     {
    //       objectiveLabel: 'Prospects',
    //       objectiveKey: 'prospects',
    //       subObjectives: []
    //     },
    //     {
    //       objectiveLabel: 'Trafic vers le site web',
    //       objectiveKey: 'trafic-vers-le-site-web',
    //       subObjectives: []
    //     }
    //   ]
    // }
  ]
};

const ModalParams = props => {
  const { themeColors } = useTheme();
  const { config, campaign, setupPendingTooltip } = props;
  const [isExpandedSetupCampaign, setIsExpandedSetupCampaign] = useState(true);
  const [isExpandedSetupAccount, setIsExpandedSetupAccount] = useState(true);
  const [mediaOrders, setMediaOrders] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [ads, setAds] = useState([]);
  const [availableCreatives, setAvailableCreatives] = useState([]);
  const [mediaLogosToDisplay, setMediaLogosToDisplay] = useState([]);
  const [activableMedias, setActivableMedias] = useState([]);
  const [importModels, setImportModels] = useState([]);
  const [campaignSetupTasks, setCampaignSetupTasks] = useState([]);
  const [updatePlatforms, setUpdatePlatforms] = useState(false);
  const [organisation, setOrganisation] = useState(null);
  const [connexions, setConnexions] = useState([]);
  const [activationShopGroups, setActivationShopGroups] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [allPlatformAccounts] = useState(
    config.groups.flatMap(group =>
      group.platformAccounts.map(account => ({
        ...account,
        groupName: group.name
      }))
    )
  );
  const [allPlatformPages] = useState(
    config.groups.flatMap(group =>
      group.platformPages.map(page => ({
        ...page,
        groupName: group.name
      }))
    )
  );
  const [formattedPlatforms, setFormattedPlatforms] = useState([]);
  const [mediaOrderObjectives, setMediaOrderObjectives] = useState([]);

  const { mediaLogos = [] } = config;
  const campaignId = campaign.id || campaign._id;
  const campaignObjective = campaign.objective || '';

  const getBudgetMediaForMediaOrder = ({ media, withDataPlan = null }) => {
    if (!withDataPlan) {
      return media.budget;
    }
    const isTraditionalMedia = withDataPlan.channels.find(
      el => el.key === media
    );
    if (isTraditionalMedia) {
      return isTraditionalMedia.budget.media;
    }
    const budgetMedia = withDataPlan.channels.reduce((acc, channel) => {
      const mediaDataFinded =
        channel?.mediaData?.find(md => md.media === media && md.enabled) ||
        null;
      if (mediaDataFinded) {
        acc += mediaDataFinded.budget.media;
      }
      return acc;
    }, 0);
    return budgetMedia;
  };

  const getBudgetMediaForLineItem = ({
    foundMediaOrders,
    foundLineItems,
    lineItem
  }) => {
    const foundLineItemsFiltered = foundLineItems.filter(
      el => el.mediaOrderId === lineItem.mediaOrderId
    );
    const mediaOrderSelected = foundMediaOrders.find(
      el => el.id === lineItem.mediaOrderId
    );
    return mediaOrderSelected?.budgetMedia || 0 / foundLineItemsFiltered.length;
  };
  const checkCampaignSetupTask = ({ masterCampaignSetupTask = [] }) => {
    if (masterCampaignSetupTask.length > 0) {
      return masterCampaignSetupTask[0];
    }
    return null;
  };

  const isSetupEnded = ({ masterCampaignSetupTask = [] }) => {
    if (masterCampaignSetupTask.length > 0) {
      if (
        masterCampaignSetupTask[0].status === 'done' ||
        masterCampaignSetupTask[0].status === 'error'
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const getSetupTask = async ({
    foundCampaignSetupTasks,
    mediaOrderId,
    masterCampaignSetupTask = [],
    media
  }) => {
    const masterCampaignSetup = checkCampaignSetupTask({
      masterCampaignSetupTask
    });
    if (masterCampaignSetup && !masterCampaignSetup.mediaOrderId) {
      const res = await ACTIVATION.getMediaSetupTaskByCampaignSetupTask([
        masterCampaignSetup.id
      ]);
      return (
        res
          .filter(el => {
            if (media === 'instagram' || media === 'facebook') {
              return el.platform === 'meta';
            }
            return el.platform === media;
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] ||
        masterCampaignSetup
      );
    }
    return (
      foundCampaignSetupTasks
        .filter(
          foundCampaignSetupTask =>
            foundCampaignSetupTask.mediaOrderId === mediaOrderId
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] ||
      null
    );
  };

  const getDisplaySetup = ({
    mediaOrder,
    allActivableMedias,
    allImportModels
  }) => {
    const model = allActivableMedias.find(
      el => el.key === mediaOrder.activableMedia
    )?.model;
    const importModel = allImportModels.find(el => el.connectorName === model);
    return importModel?.canSetUp;
  };

  const geSetup = ({
    mediaOrder,
    allActivableMedias,
    allImportModels,
    allCampaignSetupTasks,
    masterCampaignSetupTask = []
  }) => {
    const canSetup = getDisplaySetup({
      mediaOrder,
      allActivableMedias,
      allImportModels
    });
    const selectedSetupTask =
      allCampaignSetupTasks
        .filter(el => el.mediaOrderId === mediaOrder.id)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0] ||
      null;
    if (canSetup) {
      const reponse = checkCampaignSetupTask({
        masterCampaignSetupTask
      });
      if (reponse) {
        if (!reponse.mediaOrderId) {
          return isSetupEnded({
            masterCampaignSetupTask
          });
        }
      }
      if (
        selectedSetupTask &&
        (selectedSetupTask.status === 'done' ||
          selectedSetupTask.status === 'error')
      ) {
        return true;
      }
      if (
        selectedSetupTask &&
        (selectedSetupTask.status !== 'done' ||
          selectedSetupTask.status !== 'error')
      ) {
        return false;
      }

      return true;
    }
    return false;
  };

  const formatPlatform = async (
    allActivableMedias,
    foundMediaOrders,
    foundImportModels
  ) => {
    const campaignUp = await ACTIVATION.getCampaignConfigById(campaignId);

    const result = foundMediaOrders.reduce((acc, mediaOrder) => {
      const media = mediaOrder.activableMedia;
      const activableMedia = allActivableMedias.find(
        e => e.key === media && e.canSetUp
      );
      if (!activableMedia) {
        return acc;
      }

      const importModel = foundImportModels.find(
        e => e.connectorName === activableMedia.model
      );

      const mediaPlatforms = campaignUp?.platforms.filter(
        platform => platform.media === media
      );

      mediaPlatforms.forEach(platform => {
        const exist = acc.find(el => el.media === platform.media);
        if (exist) {
          exist.account =
            platform.type === 'account'
              ? { ...platform, model: importModel?.model }
              : exist.account;
          exist.page = platform.type === 'page' ? platform : exist.page;
          return;
        }
        acc.push({
          _id: platform.media,
          media: platform.media,
          account:
            platform.type === 'page'
              ? { media, model: importModel?.model }
              : { ...platform, model: importModel?.model },
          page: platform.type === 'account' ? null : platform,
          drawer: false
        });
      });

      if (mediaPlatforms.length === 0) {
        // on créé la platform si pas dans la campaign mais bien dans le mediaorder
        acc.push({
          _id: media,
          media,
          account: { media, model: importModel?.model },
          page: activableMedia?.page ? { media } : null,
          drawer: false,
          campaignId: mediaOrder.campaignId
        });
      }

      return acc;
    }, []);
    setFormattedPlatforms(result);
  };

  const fetchData = async () => {
    const [
      foundMediaOrders,
      foundLineItems,
      foundAds,
      shopGroups,
      allConnexions,
      foundMediaOrderObjectives
    ] = await Promise.all([
      ACTIVATION.getMediaOrdersByCampaignId(campaignId),
      ACTIVATION.getLineItemsByCampaignId(campaignId),
      ACTIVATION.getCreativesByCampaignId(campaignId),
      ACTIVATION.getActivationShopGoups(),
      CONNEXION.getConnexions(),
      ACTIVATION.getMediaObjectives()
    ]);

    const availableCreativesIds = foundAds.map(el => el.availableCreativeId);
    const uniqueCreativesSet = new Set(availableCreativesIds);
    const uniqueCreativesIds = [...uniqueCreativesSet];
    const [
      foundAvailableCreatives,
      masterCampaignSetupTask,
      foundCampaignSetupTasks,
      foundActivableMedias,
      foundImportModels
    ] = await Promise.all([
      ACTIVATION.getAvailableCreativeByIds(uniqueCreativesIds),
      ACTIVATION.getCampaignLastSetupTasks([campaignId]),
      ACTIVATION.getCampaignSetupTaskByCampaignId({
        ids: [campaignId]
      }),
      ACTIVATION.getActivableMedias(),
      IMPORTMODEL.get()
    ]);
    formatPlatform(foundActivableMedias, foundMediaOrders, foundImportModels);
    const group = config.groups.find(el => el._id === campaign.groupId);
    setOrganisation(group?.name || 'Custom BO');
    setConnexions(allConnexions);
    setActivationShopGroups(shopGroups);
    setMediaOrderObjectives(foundMediaOrderObjectives);

    const imageCreatives = foundAvailableCreatives.filter(
      el => el.creativeType === 'image'
    );

    setCampaignSetupTasks(masterCampaignSetupTask);
    setActivableMedias(foundActivableMedias);
    setImportModels(foundImportModels);
    const foundMediaOrdersState = [];
    for await (const mediaOrder of foundMediaOrders) {
      const setupTask = await getSetupTask({
        foundCampaignSetupTasks,
        mediaOrderId: mediaOrder._id,
        masterCampaignSetupTask: foundCampaignSetupTasks.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ),
        media: mediaOrder.activableMedia
      });
      foundMediaOrdersState.push({
        ...mediaOrder,
        budgetMedia: getBudgetMediaForMediaOrder({
          media: mediaOrder.activableMedia,
          withDataPlan: campaign.withDataPlan
        }),
        externalId: mediaOrder.externalId?.externalId,
        isExpanded: true,
        isEditing: false,
        displaySetup: getDisplaySetup({
          mediaOrder,
          allActivableMedias: foundActivableMedias,
          allImportModels: foundImportModels
        }),
        setupTask,
        setup: geSetup({
          mediaOrder,
          allActivableMedias: foundActivableMedias,
          allImportModels: foundImportModels,
          allCampaignSetupTasks: foundCampaignSetupTasks,
          masterCampaignSetupTask
        })
      });
    }

    setMediaOrders(foundMediaOrdersState);
    setLineItems(
      foundLineItems.map(lineItem => ({
        ...lineItem,
        budgetMedia: getBudgetMediaForLineItem({
          foundMediaOrders: foundMediaOrdersState,
          foundLineItems,
          lineItem
        }),
        externalId: lineItem.externalId?.externalId,
        isExpanded: true,
        isEditing: false
      }))
    );
    setAds(
      foundAds.map(ad => {
        const availableCreative = foundAvailableCreatives.find(
          el => el._id === ad.availableCreativeId
        );
        return {
          ...ad,
          availableCreative,
          externalId: ad.externalId?.externalId,
          isExpanded: true,
          isEditing: false
        };
      })
    );
    setAvailableCreatives(imageCreatives);
    setLoaded(true);
  };

  const toggleExpandedCampaignSetup = key => {
    if (key === 'all') {
      setIsExpandedSetupCampaign(!isExpandedSetupCampaign);
      return;
    }
    const newOrders = [...mediaOrders];
    const foundOrder = newOrders.find(el => el.id === key);
    foundOrder.isExpanded = !foundOrder.isExpanded;
    setMediaOrders(newOrders);
  };

  const downloadAssets = async () => {
    const id = campaign.id || campaign._id;

    try {
      const allAssets = await ACTIVATION.downloadAssetsByCampaignId(id);

      if (allAssets) {
        const blob = new Blob([allAssets], {
          type: 'application/zip'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'assets.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Failed to retrieve the assets.');
      }
    } catch (error) {
      console.error('Error downloading assets:', error);
    }
  };

  const getStatusChip = (activableMedia, mediaSetupTask = {}) => {
    if (activableMedia.canSetUp !== true) {
      return (
        <Chip
          label="Setup manuel"
          icon={<ReportProblemIcon />}
          style={{
            backgroundColor: themeColors.badgeDomains,
            borderRadius: '3px',
            fontWeight: 600,
            fontSize: '12px',
            marginLeft: '18px',
            marginRight: '18px'
          }}
        />
      );
    }
    switch (mediaSetupTask?.status) {
      case 'toDo':
        return (
          <Chip
            label="Synchronisable"
            icon={<ErrorOutlineIcon />}
            style={{
              backgroundColor: themeColors.badgeFunnel,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );
      case 'done':
        return (
          <Chip
            label="Synchronisé"
            icon={<CheckIcon />}
            style={{
              backgroundColor: themeColors.badgeActif,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );

      case 'error':
        return (
          <Tooltip title={mediaSetupTask.errorMsg}>
            <Chip
              label="Erreur"
              icon={<CancelOutlinedIcon />}
              style={{
                backgroundColor: themeColors.error_gestion_campagne,
                borderRadius: '3px',
                fontWeight: 600,
                fontSize: '12px',
                marginLeft: '18px',
                marginRight: '18px'
              }}
            />
          </Tooltip>
        );
      case 'partial':
        return (
          <Chip
            label="Setup manuel"
            icon={<CheckIcon />}
            style={{
              backgroundColor: themeColors.badgeActif,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );
      // TODO : Remove status
      // Actuellement on reçoit des campaignSetupTask et pas des mediaSetupTask, les status sont diff.
      case 'uploadingSegments':
      case 'uploadInProgress':
      case 'segmentsExported':
      case 'uploadingCreatives':
      case 'creativesUploaded':
      case 'SettingUpCampaign':
        return (
          <Chip
            label="En cours"
            style={{
              backgroundColor: themeColors.grey_light_button,
              borderRadius: '3px',
              fontWeight: 600,
              fontSize: '12px',
              marginLeft: '18px',
              marginRight: '18px'
            }}
          />
        );
      case 'creatingOrder':
      case 'orderCreated':
      case 'creatingLineItems':
      case 'lineItemsCreated':
      case 'attachingAds':
      default:
        switch (campaignSetupTasks[0]?.status) {
          case 'toDo':
          case 'uploadingSegments':
          case 'segmentsExported':
          case 'uploadingCreatives':
          case 'creativesUploaded':
          case 'SettingUpCampaign':
            return (
              <Chip
                label="En cours"
                style={{
                  backgroundColor: themeColors.grey_light_button,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
          case 'done':
            return (
              <Chip
                label="Synchronisé"
                icon={<CheckIcon />}
                style={{
                  backgroundColor: themeColors.badgeActif,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
          case 'error':
            return (
              <Chip
                label="Erreur"
                icon={<CancelOutlinedIcon />}
                style={{
                  backgroundColor: themeColors.error_gestion_campagne,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
          default:
            return (
              <Chip
                label="Synchronisable"
                icon={<ErrorOutlineIcon />}
                style={{
                  backgroundColor: themeColors.badgeFunnel,
                  borderRadius: '3px',
                  fontWeight: 600,
                  fontSize: '12px',
                  marginLeft: '18px',
                  marginRight: '18px'
                }}
              />
            );
        }
    }
  };

  const getCampaignSetupTasks = async () => {
    const masterCampaignTask = await ACTIVATION.getCampaignLastSetupTasks([
      campaignId
    ]);
    if (masterCampaignTask) {
      setCampaignSetupTasks(masterCampaignTask);
    }

    const allCampaignSetupTasks = await ACTIVATION.getCampaignSetupTaskByCampaignId(
      { ids: [campaignId] }
    );

    const newmediaOrders = [];
    for await (const el of mediaOrders) {
      const setupTask = await getSetupTask({
        foundCampaignSetupTasks: allCampaignSetupTasks,
        mediaOrderId: el._id,
        masterCampaignSetupTask: allCampaignSetupTasks.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ),
        media: el.activableMedia
      });
      newmediaOrders.push({
        ...el,
        setupTask,
        setup: geSetup({
          mediaOrder: el,
          allActivableMedias: activableMedias,
          allImportModels: importModels,
          allCampaignSetupTasks,
          masterCampaignSetupTask: allCampaignSetupTasks.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        })
      });
    }
    setMediaOrders(newmediaOrders);
  };

  const launchSetupCampaign = async () => {
    await ACTIVATION.launchCampaignSetup(campaignId);
    setMediaOrders(
      mediaOrders.map(el => {
        return {
          ...el,
          setup: false
        };
      })
    );
    getCampaignSetupTasks();
  };

  const launchSetup = async mediaOrder => {
    await ACTIVATION.postCampaignSetup({
      campaignConfigsId: campaignId,
      mediaOrder
    });
    setMediaOrders(
      mediaOrders.map(el => {
        if (el.activableMedia === mediaOrder.activableMedia) {
          return { ...el, setup: false };
        }
        return el;
      })
    );
    getCampaignSetupTasks();
  };

  const handleSaveMediaOrder = async mediaOrder => {
    const order = await ACTIVATION.getMediaOrderById(mediaOrder.id);
    if (order.externalId?.externalId || order.objective || order.subObjective) {
      order.externalId = { externalId: mediaOrder?.externalId };
      order.objective = mediaOrder?.objective || '';
      order.subObjective = mediaOrder?.subObjective || '';
    } else {
      order.externalId = {
        externalId: mediaOrder?.externalId
      };
      order.objective = mediaOrder?.objective || '';
      order.subObjective = mediaOrder?.subObjective || '';
    }
    await ACTIVATION.updateMediaOrderById(mediaOrder.id, order);
  };

  const handleSaveLineItem = async (id, newExternalId) => {
    const lineItem = await ACTIVATION.getLineItemById(id);
    if (lineItem.externalId) {
      lineItem.externalId.externalId = newExternalId;
    } else {
      lineItem.externalId = {
        externalId: newExternalId
      };
    }
    await ACTIVATION.updateLineItemById(id, lineItem);
  };

  const handleSaveAd = async (id, newExternalId) => {
    const ad = await ACTIVATION.getCreativeById(id);
    if (ad.externalId) {
      ad.externalId.externalId = newExternalId;
    } else {
      ad.externalId = {
        externalId: newExternalId
      };
    }
    await ACTIVATION.updateCreativeById(id, ad);
  };

  useEffect(() => {
    const logosToDisplay = mediaOrders.map(mediaOrder => {
      const key =
        mediaOrder.activableMedia === 'search'
          ? 'googleads'
          : mediaOrder.activableMedia.toLowerCase();
      const matchingLogo = mediaLogos.find(
        logo => logo.key.toLowerCase() === key
      );
      return matchingLogo ? matchingLogo.base64Image : null;
    });

    setMediaLogosToDisplay(logosToDisplay);
    // eslint-disable-next-line
  }, [mediaOrders, mediaLogos]);

  const savePlatform = async newPlatforms => {
    const platforms = newPlatforms.reduce((acc, formattedPlatform) => {
      if (formattedPlatform.page) {
        acc.push(formattedPlatform.page);
      }
      if (formattedPlatform.account) {
        acc.push(formattedPlatform.account);
      }
      return acc;
    }, []);
    await ACTIVATION.updateCampaignConfig(campaignId, {
      platforms
    });
  };

  const displayDrawerInput = key => {
    const getCertified = data => {
      if (data === null) {
        return null;
      }
      if (data.media === 'sms') {
        if (data?.config?.connexionId === '') {
          return {
            ...data,
            config: 'noExist'
          };
        }
      } else {
        if (data?.config?.config?.externalId === '') {
          return {
            ...data,
            config: 'noExist'
          };
        }
      }
      return data;
    };
    const newPlatforms = formattedPlatforms.map(el => {
      if (el._id === key) {
        return {
          ...el,
          account: getCertified(el.account),
          page: getCertified(el.page),
          drawer: !el.drawer
        };
      }
      return { ...el };
    });
    setFormattedPlatforms(newPlatforms);

    if (!newPlatforms.some(el => el.drawer) && updatePlatforms) {
      savePlatform(newPlatforms);
    }
  };

  const isObject = data => {
    return data !== null && typeof data === 'object';
  };

  const getMediaModelByMedia = media => {
    const activableMediaFounded = activableMedias.find(
      activableMedia => activableMedia.key === media
    );

    const importModelFounded = importModels.find(
      importModel => importModel.connectorName === activableMediaFounded.model
    );

    return importModelFounded.model;
  };

  const handleChangeExternalId = (type, media) => value => {
    let tmp = [];
    const activableMediaModel =
      activableMedias.find(el => el.key === media)?.model || null;
    const connexionId =
      connexions.find(el => el.model === activableMediaModel)?._id || null;

    if (media === 'sms') {
      tmp = formattedPlatforms.map(formattedPlatform => {
        if (formattedPlatform.media === media) {
          if (isObject(formattedPlatform[type].config)) {
            formattedPlatform[type].config = {
              connexionId: value?.key,
              name: value?.label
            };
          } else {
            formattedPlatform[type] = {
              media,
              type,
              config: {
                connexionId: value?.key,
                name: value?.label
              }
            };
          }
        }
        return formattedPlatform;
      });
    } else {
      tmp = formattedPlatforms.map(formattedPlatform => {
        if (formattedPlatform.media === media) {
          if (isObject(formattedPlatform[type]?.config)) {
            if (isObject(formattedPlatform[type]?.config?.config)) {
              formattedPlatform[type].config = {
                model: formattedPlatform[type].config.model,
                name: value?.group ? value.group : organisation,
                status: 'validated',
                config: {
                  externalId: value?.key,
                  connexionId
                }
              };
            } else {
              formattedPlatform[type].config = {
                config: {
                  externalId: value?.key,
                  connexionId
                }
              };
            }
          } else {
            formattedPlatform[type] = {
              media,
              type,
              config: {
                model: getMediaModelByMedia(media),
                name: value?.group ? value.group : organisation,
                status: 'validated',
                config: {
                  externalId: value?.key,
                  connexionId
                }
              }
            };
          }
        }
        return formattedPlatform;
      });
    }
    if (value?.group) {
      setOrganisation(value.group);
    }
    setFormattedPlatforms(tmp);
  };

  useEffect(() => {
    const isUpdate = formattedPlatforms.some(el => el.drawer);
    if (isUpdate) {
      setUpdatePlatforms(isUpdate);
    }
  }, [formattedPlatforms]);

  useEffect(() => {
    // formatPlatform();
    fetchData();
    // eslint-disable-next-line
  }, []);

  const isCampaignSetupPending =
    campaignSetupTasks.length > 0 &&
    !['done', 'error'].includes(campaignSetupTasks[0].status);
  const launchButtonColor = isCampaignSetupPending
    ? themeColors.themeV2.detail
    : themeColors.activation;

  const handleCampaignOptions = (camp, opt) => {
    if (!opt) {
      return;
    }

    const typeOfPrice = {
      media: 'percentMedia',
      fix: 'fix',
      total: 'percentTotal'
    };

    const calculatePrice = (typeOfBudget, percentage) => {
      const budget = Math.round(
        typeOfBudget === typeOfPrice.total
          ? camp.budget
          : camp.withDataPlan.budget.media
      );

      return Math.round(budget * (percentage / 100));
    };

    let optionPrice = 0;

    const { type, amount } = opt.price;

    switch (type) {
      case typeOfPrice.fix:
        optionPrice = amount;
        break;
      default:
        optionPrice = calculatePrice(type, amount);
        break;
    }
    // eslint-disable-next-line consistent-return
    return opt.amount ? opt.amount : optionPrice;
  };

  function sumAndRoundBudget(camp) {
    const {
      budgetOptions,
      withDataPlan: {
        budget: { media, technicalFees, data, functionalFees }
      }
    } = camp;
    return Math.round(
      media + technicalFees + data + functionalFees + budgetOptions
    );
  }

  const invoiceItemConfigs = [
    {
      label: "Achat d'espace",
      amount: campaign.withDataPlan?.budget?.media
    },
    {
      label: 'Data',
      amount: campaign.withDataPlan?.budget?.data
    },
    {
      label: 'Frais de routage',
      amount: campaign.withDataPlan?.budget?.functionalFees
    },
    {
      label: 'Frais techniques',
      amount: campaign.withDataPlan?.budget?.technicalFees
    },
    {
      label: 'Marge',
      amount: campaign.withDataPlan?.budget?.margin,
      showItem: !!campaign.withDataPlan?.budget?.margin
    }
  ];

  const findCampaignObjective = (objectives, campaignObjectiveKey) =>
    objectives.platforms.reduce((label, platform) => {
      if (label) return label;

      const objective = platform.objectives.find(
        obj => obj.objectiveKey === campaignObjectiveKey
      );

      if (objective) {
        return `${objective.objectiveLabel} - Maximiser le nombre d'impressions`;
      }

      return null;
    }, null);

  const campaignObjectiveLabel = findCampaignObjective(
    mockedObjectives,
    campaignObjective
  );

  const filterObjectives = (objectives, labels, campaignObjLabel) => {
    const result = {
      platforms: labels?.platforms?.map(platform => {
        const platformObjectives = objectives?.filter(
          mediaObjective => mediaObjective?.platform === platform?.platform
        );

        const filteredObjectives = platform?.objectives
          .map(objective => {
            const matchingObjective = platformObjectives?.find(
              mediaObjective =>
                mediaObjective?.objectiveKey === objective?.objectiveKey
            );

            if (matchingObjective) {
              return {
                ...objective,
                subObjectives: objective?.subObjectives.filter(subObjective =>
                  matchingObjective?.subObjectives?.includes(
                    subObjective?.subObjectiveKey
                  )
                )
              };
            }

            return null; // If no match add null
          })
          .filter(Boolean); // Remove falsy values => null in this case

        return {
          ...platform,
          objectives: filteredObjectives,
          campaignObjectiveLabel: campaignObjLabel
        };
      })
    };

    return result;
  };

  const filteredObjectives = filterObjectives(
    mediaOrderObjectives,
    mockedObjectives,
    campaignObjectiveLabel
  );

  const formatOptions = () => {
    const paddingLeft = campaign.options?.length > 1 ? '40px' : null;
    const listCampaignOptions =
      campaign.options?.map(option => (
        <InvoiceItem
          paddingLeft={paddingLeft}
          label={option.name}
          amount={numeral(handleCampaignOptions(campaign, option)).format(
            '0,0 $'
          )}
        />
      )) || [];
    return (
      <>
        {listCampaignOptions.length > 1 && (
          <Item justify="flex-start">
            <Item flex>
              <Text>Options</Text>
            </Item>
            <Item
              xs
              justify="flex-start"
              style={{
                paddingRight: '5px',
                paddingLeft: '5px'
              }}
            >
              <Divider
                color="light"
                noFade
                style={{
                  width: '100%',
                  height: '0.5px'
                }}
              />
            </Item>
            <Item flex>
              <Text fontWeight={700}>{`${numeral(
                campaign?.budgetOptions
              ).format('0,0 $')}`}</Text>
            </Item>
          </Item>
        )}
        {listCampaignOptions}
      </>
    );
  };

  return (
    <Container>
      <IntervalTimer
        timeout={10000}
        callback={getCampaignSetupTasks}
        enabled
        repeat
      />
      <Row spacing={1} style={{ paddingBottom: '30px' }}>
        <Item justify="flex-start">
          <Text fontWeight={700} fontSize="20px">
            {campaign.name}&nbsp;
            <Text component="span" fontSize="20px" fontWeight={400}>
              ({campaignId})
            </Text>
          </Text>
        </Item>
      </Row>
      {campaign.withDataPlan && (
        <Row spacing={1}>
          <Item>
            <FormSection title="Récapitulatif" xs={3}>
              <Container>
                <Row spacing={1}>
                  <Item xs={11} alignItems="flex-start">
                    <Item xs={2}>
                      <Text fontWeight={700} fontSize="14px">
                        Facturation
                      </Text>
                    </Item>
                    <Item
                      xs={10}
                      style={{
                        backgroundColor: themeColors.themeV2.btnGreyBackground,
                        paddingBottom: '24px',
                        paddingRight: '24px'
                      }}
                    >
                      <Row>
                        {invoiceItemConfigs.map(item => (
                          <InvoiceItem
                            key={item.label}
                            label={item.label}
                            amount={item.amount}
                            showItem={item.showItem}
                          />
                        ))}

                        {campaign.options.length > 0 && formatOptions()}

                        <InvoiceItem
                          label="Total HT"
                          isBoldLabel
                          amount={sumAndRoundBudget(campaign)}
                        />
                      </Row>
                    </Item>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      )}
      <Row spacing={1}>
        <ExpendedItem
          toggleExpanded={() => {
            toggleExpandedCampaignSetup('all');
          }}
          isExpanded={isExpandedSetupCampaign}
          title="Setup campaign"
        />
      </Row>

      <Loading loading={!loaded} />
      <Row spacing={0}>
        <Item justify="flex-start">
          <Collapse
            in={isExpandedSetupCampaign}
            timeout="auto"
            unmountOnExit
            style={{ width: '100%' }}
          >
            {mediaOrders.length > 0 &&
              mediaOrders.map((mediaOrder, mediaOrderIndex) => {
                const matchingLineItems = lineItems.filter(
                  lineItem => lineItem.mediaOrderId === mediaOrder.id
                );
                const activableMedia = activableMedias.find(
                  el => el.key === mediaOrder.activableMedia
                );
                return (
                  <MediaOrderItem
                    mediaOrder={mediaOrder}
                    toggleExpanded={toggleExpandedCampaignSetup}
                    mediaLogoDisplay={mediaLogosToDisplay[mediaOrderIndex]}
                    activableMedia={activableMedia}
                    getStatusChip={getStatusChip}
                    setMediaOrders={setMediaOrders}
                    handleSaveMediaOrder={handleSaveMediaOrder}
                    mediaSetupTask={mediaOrder.setupTask}
                    launchSetup={launchSetup}
                    objectives={filteredObjectives}
                  >
                    <Row spacing={1} justify="flex-start">
                      <Collapse
                        in={mediaOrder.isExpanded}
                        timeout="auto"
                        unmountOnExit
                        style={{
                          width: '100%'
                        }}
                      >
                        {matchingLineItems.length > 0 &&
                          matchingLineItems.map(lineItem => {
                            return (
                              <LineItem
                                isCampaignSetupPending={isCampaignSetupPending}
                                lineItem={{
                                  ...lineItem,
                                  budgetMedia:
                                    lineItem.budgetMedia /
                                    matchingLineItems.length
                                }}
                                toggleExpanded={toggleExpandedCampaignSetup}
                                mediaLogoDisplay={
                                  mediaLogosToDisplay[mediaOrderIndex]
                                }
                                activableMedia={activableMedia}
                                getStatusChip={getStatusChip}
                                setLineItems={setLineItems}
                                handleSaveLineItem={handleSaveLineItem}
                                mediaSetupTask={mediaOrder.setupTask}
                              >
                                <Row spacing={1}>
                                  {ads.length > 0 && (
                                    <>
                                      {ads
                                        .filter(
                                          ad =>
                                            ad.mediaOrderId === mediaOrder.id &&
                                            ad.lineItemId === lineItem.id
                                        )
                                        .map(ad => {
                                          const asset = availableCreatives.find(
                                            el =>
                                              el.id === ad.availableCreativeId
                                          );
                                          if (asset) {
                                            const keys = Object.keys(
                                              asset.mediaDatas[0]
                                            );
                                            const fileObject = asset.mediaDatas[0][
                                              keys[0]
                                            ]?.find(el => el.key === 'file');
                                            if (fileObject) {
                                              asset.uploadedAssetId =
                                                fileObject.value;
                                            }
                                          }
                                          return (
                                            <AdItem
                                              isCampaignSetupPending={
                                                isCampaignSetupPending
                                              }
                                              ad={ad}
                                              getStatusChip={getStatusChip}
                                              asset={asset}
                                              setAds={setAds}
                                              activableMedia={activableMedia}
                                              mediaSetupTask={
                                                mediaOrder.setupTask
                                              }
                                              handleSaveAd={handleSaveAd}
                                            />
                                          );
                                        })}
                                    </>
                                  )}
                                </Row>
                              </LineItem>
                            );
                          })}
                      </Collapse>
                    </Row>
                  </MediaOrderItem>
                );
              })}
          </Collapse>
        </Item>
      </Row>
      <Row
        spacing={1}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '50px'
        }}
      >
        <Item flex>
          <Button
            variant="contained"
            size="medium"
            color={themeColors.black}
            onClick={() => {
              downloadAssets();
            }}
          >
            <DownloadIcon />
            &nbsp;
            <Text fontSize="12px" fontWeight={600} color="inherit">
              Télécharger tous les assets
            </Text>
          </Button>
        </Item>
        <Item flex>
          {' '}
          <Tooltip
            title={setupPendingTooltip}
            disabled={!isCampaignSetupPending}
          >
            <Button
              variant="contained"
              size="medium"
              color={launchButtonColor}
              fontColor={isCampaignSetupPending && themeColors.keep_dark}
              onClick={() => {
                launchSetupCampaign();
              }}
              disabled={isCampaignSetupPending}
            >
              <Icon
                icon="setUpAutoIcon"
                attrs={{
                  circleColor: themeColors.keep_light,
                  arrowColor: isCampaignSetupPending
                    ? themeColors.themeV2.subtitle
                    : themeColors.activation
                }}
              />
              &nbsp;
              <Text fontSize="12px" fontWeight={600} color="inherit">
                Lancer le setup sur tous les médias compatibles
              </Text>
            </Button>
          </Tooltip>
        </Item>
      </Row>
      {formattedPlatforms.length > 0 ? (
        <>
          <Row spacing={1} style={{ marginTop: '20px' }}>
            <ExpendedItem
              title="Configuration des comptes"
              toggleExpanded={() => {
                setIsExpandedSetupAccount(!isExpandedSetupAccount);
              }}
              isExpanded={isExpandedSetupAccount}
            />
          </Row>
          <Row spacing={1}>
            <Item justify="flex-start">
              <Collapse
                in={isExpandedSetupAccount}
                timeout="auto"
                unmountOnExit
                style={{ width: '100%' }}
              >
                <TableConnectorComponent
                  setupPendingTooltip={setupPendingTooltip}
                  isCampaignSetupPending={isCampaignSetupPending}
                  mediaLogos={mediaLogos}
                  allPlatformAccounts={allPlatformAccounts}
                  allPlatformPages={allPlatformPages}
                  formattedPlatforms={formattedPlatforms}
                  displayInput={displayDrawerInput}
                  handleChangeExternalId={handleChangeExternalId}
                  campaign={campaign}
                  activationShopGroups={activationShopGroups}
                />
              </Collapse>
            </Item>
          </Row>
        </>
      ) : (
        <Loading loading={!loaded} />
      )}
    </Container>
  );
};

export default ModalParams;
