import React, { useCallback, useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components/ModalForm';

const ForcedChannelContainer = () => {
  const { currentPresetId } = useNavigation();
  const { adminRights } = useUser();
  const [rows, setRows] = React.useState([]);
  const [forcedChannel, setForcedChannelList] = React.useState([]);
  const [config, setConfig] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [selectedForcedChannel, setSelectedForcedChannel] = React.useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectForcedChannel = id => {
    const selected = forcedChannel.find(el => el._id === id);
    setSelectedForcedChannel(selected);
    return selected;
  };
  const closeModal = () => {
    setModal(null);
    selectForcedChannel({});
  };

  const getForcedChannel = async () => {
    try {
      const result = await ACTIVATION.getForcedChannel(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getChannels = async () => {
    try {
      const result = await ACTIVATION.getChannels();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const openCreateModal = () => {
    if (adminRights?.activation?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async datas => {
    await ACTIVATION.createForcedChannel({ preset: currentPresetId, ...datas });
    setLoaded(false);
    closeModal();
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectForcedChannel(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async datas => {
    await ACTIVATION.updateForcedChannel(selectedForcedChannel._id, datas);

    setLoaded(false);
    closeModal();
  };

  const onDelete = async id => {
    const selected = selectForcedChannel(id);
    await ACTIVATION.deleteForcedChannel(selected._id);
    closeModal();
    setLoaded(false);
  };

  // eslint-disable-next-line
  const getDate = useCallback(async () => {
    const allchannels = await getChannels();
    const forcedChannels = await getForcedChannel();

    const keys = [];

    const mapped = forcedChannels.map(item => {
      const newItem = {};
      keys.push(item.channel);
      const channel = allchannels.find(el => el.key === item.channel);
      newItem._id = item._id;
      newItem.channel = channel;
      newItem.channelName = channel.label;
      newItem.percent = item.percent;
      return newItem;
    });

    const channels = allchannels.filter(el => keys.indexOf(el.key) === -1);

    setLoaded(true);
    setForcedChannelList(mapped);
    setRows(mapped);
    setConfig({ channels });
  });

  useEffect(() => {
    if (!loaded) {
      getDate();
    }
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <Container>
      <Row spacing={4} show={config?.channels?.length !== 0}>
        <Item xs={3} />
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreateModal} text="Ajouter" />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              config={{ channels: config.channels }}
              onValidate={onCreate}
              actionName="Ajouter"
            />
          )}
        </Item>
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Canal',
                  id: 'channelName'
                },
                {
                  label: 'Part du budget',
                  id: 'percent',
                  format: value => numeral(value).format('0[.]000%')
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
              onDelete={onDelete}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{
                ...selectedForcedChannel
              }}
              actionName="Modifier le canal"
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ForcedChannelContainer;
